import channel from './channel';
import channelSubscription from './channelSubscription';
import channelsState from './channelsState';
import country from './country';
import region from './region';
import showChannel from './showChannel';
import termsOfUseAgreement from './termsOfUseAgreement';
import tokenUser from './tokenUser';
import user from './user';
import privilege from './privilege';

export default {
  channel,
  channelSubscription,
  channelsState,
  country,
  region,
  showChannel,
  termsOfUseAgreement,
  tokenUser,
  user,
  privilege,
};
