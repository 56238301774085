import ChannelVisibility from 'channels/models/ChannelVisibility';
import ShowChannel from 'channels/models/ShowChannel';
import { Factory } from 'fishery';
import channel from './channel';
import channelSubscription from './channelSubscription';

export default Factory.define<ShowChannel>(() => ({
  ...channel.build(),
  url: 'https://example.com',
  owners: [],
  subscription: channelSubscription.build(),
  publishers: [],
  visibility: ChannelVisibility.Visible,
  countries: [],
}));
