import React, { ReactElement, useReducer } from 'react';
import Accordion, { AccordionItem } from '@ingka/accordion';
import RadioButtonGroup from '@ingka/radio-button-group';
import InputField from '@ingka/input-field';
import {
  CheckboxGroup, Stack, Space, Inline, FileInput,
} from 'ui';
import Category from 'channels/models/Category';
import _, { isNull } from 'lodash';
import Button from '@ingka/button';
import TextArea from '@ingka/text-area';
import Checkbox from '@ingka/checkbox';
import channelFormReducer from 'channels/channelFormReducer';
import ChannelVisibility from 'channels/models/ChannelVisibility';
import JSONAPISerializer from 'json-api-serializer';
import ChannelFormState from 'channels/models/ChannelFormState';
import fileToBase64 from 'files/fileToBase64';
import { useHistory } from 'react-router-dom';
import CountryCheckboxGroup from 'regions/components/CountryCheckboxGroup';
import ShowChannel from 'channels/models/ShowChannel';
import getInitialChannelFormState from 'channels/getInitialChannelFormState';
import errorsForField from 'validation/errorsForField';
import { CheckboxGroupProps } from 'ui/components/CheckboxGroup';
import FormFieldStatus from '@ingka/form-field-status';
import approvalFormReducer from 'channels/approvalFormReducer';
import getInitialApprovalFormState from 'channels/getInitialApprovalFormState';
import ApprovalFormState from 'channels/models/ApprovalFormState';

type Props = {
  onSubmit: (channel: ChannelFormState) => unknown;
  onApprovalSubmit: (approval: ApprovalFormState) => unknown;
  errors?: JSONAPISerializer.ErrorObject[];
  channel?: Partial<ShowChannel>;
  defaultCountriesChecked?: boolean;
  isLoading? : boolean;
  isAdmin?: boolean;
  formType?: string
};

export default function ChannelForm({
  onSubmit,
  onApprovalSubmit,
  errors = [],
  channel,
  defaultCountriesChecked,
  isLoading = false,
  isAdmin = false,
  formType = '',
}: Props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(channelFormReducer, getInitialChannelFormState(channel));
  const [approvalState, dispatchApproval] = useReducer(
    approvalFormReducer, getInitialApprovalFormState(channel),
  );
  const approvalList = [
    {
      id: 'radio1',
      name: 'groupApproval',
      label: 'Reject channel request',
      value: 'OFF',
      disabled: false,
    },
    {
      id: 'radio2',
      name: 'groupApproval',
      label: 'Approve channel request',
      value: 'ON',
      disabled: false,
    },
  ];
  let requestRejected = false;
  function isValid(attribute: string): boolean {
    return _.isEmpty(errorsForField(errors, attribute).body);
  }
  function onApprovalChange(ev: any) {
    requestRejected = ev.target.id === 'radio1';
    dispatchApproval({ type: 'SET_PROPERTY', name: 'approvalStatus', value: requestRejected ? 'Rejected' : 'Approved' });
    const el = document.getElementById('reject');
    if (!isNull(el)) {
      el.hidden = !requestRejected;
    }
  }
  function renderCtaButton() : ReactElement {
    if (formType === 'new') {
      return (
        <Button text="Submit channel request" htmlType="submit" type="emphasised" loading={isLoading} />);
    }
    if (formType === 'edit' && isAdmin && channel?.approvalStatus === 'Pending') {
      return (
        <Button
          text="Send approval response"
          htmlType="submit"
          type="emphasised"
          loading={isLoading}
          disabled={approvalState.approvalStatus === 'Pending'}
        />
      );
    }
    return (
      <Button text={'Save' || formType} htmlType="submit" type="emphasised" loading={isLoading} />);
  }
  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        if (isAdmin && channel?.approvalStatus === 'Pending') {
          onApprovalSubmit(approvalState);
        } else {
          onSubmit(state);
        }
      }}
    >
      <Stack space={Space.Space300}>
        <InputField
          id="name"
          type="text"
          label="Name"
          value={state.name}
          onChange={({ target }) => dispatch({ type: 'SET_PROPERTY', name: 'name', value: target.value })}
          validation={errorsForField(errors, 'name')}
          valid={isValid('name')}
          shouldValidate={!isValid('name')}
          req
        />
        <InputField
          id="url"
          type="text"
          label="URL"
          value={state.url}
          req={false}
          onChange={({ target }) => dispatch({ type: 'SET_PROPERTY', name: 'url', value: target.value })}
          validation={errorsForField(errors, 'url')}
          valid={isValid('url')}
          shouldValidate={!isValid('url')}
        />
        <TextArea
          id="description"
          label="Description"
          value={state.description}
          required
          onChange={({ target }) => dispatch({ type: 'SET_PROPERTY', name: 'description', value: target.value })}
          validation={errorsForField(errors, 'description')}
          valid={isValid('description')}
          shouldValidate={!isValid('description')}
        />
        <InputField
          id="owners"
          type="text"
          label="Channel owners"
          value={state.ownersText}
          req
          onChange={({ target }) => dispatch({ type: 'SET_OWNERS', value: target.value })}
          validation={errorsForField(errors, 'owners')}
          valid={isValid('owners')}
          shouldValidate={!isValid('owners')}
          helper={{ body: 'Enter email addresses separated by comma.' }}
        />
        <InputField
          id="publishers"
          type="text"
          label="Publishers"
          value={state.publishersText}
          req={false}
          onChange={({ target }) => dispatch({ type: 'SET_PUBLISHERS', value: target.value })}
          validation={errorsForField(errors, 'publishers')}
          valid={isValid('publishers')}
          shouldValidate={!isValid('publishers')}
          helper={{ body: 'Enter email addresses separated by comma.' }}
        />

        <Stack space={Space.Space100}>
          <p>Select the categories for this channel.</p>

          <CheckboxGroup
            onChange={(list: CheckboxGroupProps['list']) => {
              dispatch({
                type: 'SET_PROPERTY',
                name: 'categories',
                value: list.filter((i) => i.checked).map((i) => i.label as Category),
              });
            }}
            list={Object.values(Category).map((category) => ({
              id: _.kebabCase(category),
              label: category,
              checked: state?.categories?.some((c) => c === category),
            }))}
          />
          <FormFieldStatus valid={isValid('categories')} shouldValidate={!isValid('categories')} validation={errorsForField(errors, 'categories')} />
        </Stack>

        <Accordion>
          <AccordionItem id="regions-and-countries" title="Regions and Countries">
            <p>Select the regions/countries this channel is sharing information about.</p>
            <CountryCheckboxGroup
              defaultChecked={defaultCountriesChecked}
              selectedCountries={state.countries}
              onChange={(value) => dispatch({ type: 'SET_PROPERTY', name: 'countries', value })}
            />
          </AccordionItem>
        </Accordion>

        <FormFieldStatus valid={isValid('countries')} shouldValidate={!isValid('countries')} validation={errorsForField(errors, 'countries')} />

        <FileInput
          id="thumbnailUrl"
          label="Thumbnail image"
          validation={errorsForField(errors, 'thumbnailUrl')}
          valid={isValid('thumbnailUrl')}
          helper={{
            body: 'The image to be shown in the search results. Ideal size is 400x300 pixels.',
          }}
          onChange={(event) => {
            const { files } = event.target;

            if (files.length < 1) {
              return;
            }

            fileToBase64(files[0]).then((value) => dispatch({ type: 'SET_PROPERTY', name: 'thumbnailUrl', value }));
          }}
        />
        { channel?.id !== undefined
          ? (
            <Checkbox
              id="hideChannel"
              label="Hide channel"
              checked={state.visibility === ChannelVisibility.Hidden}
              onChange={(e) => {
                dispatch({ type: 'SET_VISIBILITY', isHidden: e.target.checked });
              }}
            />
          ) : <></>}
        { isAdmin && channel?.approvalStatus === 'Pending'
          ? (
            <Stack space={Space.Space200}>
              <hr className="demo-divider__hr-horizontal" />
              <RadioButtonGroup name="Channel request decision" list={approvalList} onClick={onApprovalChange} />
              <div id="reject" hidden>
                <TextArea
                  id="approvalMessage"
                  label="Rejection Details"
                  value={approvalState.approvalMessage}
                  onChange={({ target }) => dispatchApproval({ type: 'SET_PROPERTY', name: 'approvalMessage', value: target.value })}
                  validation={errorsForField(errors, 'approvalMessage')}
                  valid={isValid('approvalMessage')}
                  shouldValidate={!isValid('approvalMessage')}
                />
              </div>
            </Stack>
          ) : <></>}
        <Inline space={Space.Space100}>
          <Button text="Cancel" htmlType="button" onClick={() => history.goBack()} />
          {renderCtaButton()}
        </Inline>
      </Stack>
    </form>
  );
}

ChannelForm.defaultProps = {
  errors: [],
  channel: undefined,
  defaultCountriesChecked: true,
  isLoading: false,
  isAdmin: false,
  formType: '',
};
