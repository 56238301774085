import ApprovalFormState from './models/ApprovalFormState';
import ChannelVisibility from './models/ChannelVisibility';

const INITIAL_APPROVAL_FORM_STATE: ApprovalFormState = {
  name: '',
  url: '',
  description: '',
  owners: [],
  ownersText: '',
  publishers: [],
  publishersText: '',
  categories: [],
  countries: [],
  thumbnailUrl: '',
  visibility: ChannelVisibility.Visible,
  channelId: '',
  approvalStatus: '',
  approvalMessage: '',
};

export default INITIAL_APPROVAL_FORM_STATE;
